.App {
  text-align: center;
}

.tgme_background_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  background-image: url('./image/cats.svg');
  background-size: contain;
}

.tgme_background, .tgme_background{
  -webkit-mask: center repeat;
  -webkit-mask-size: 420px auto;
}

.tgme_page{
  position: relative;
  margin: 16px auto;
  padding: 32px 0;
  max-width: 400px;
  background: #1e1e1e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  transition: all .15s ease .15s;
}

.tgme_page_photo{
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 16px;
}

.tgme_page_photo_image{
  width: 122px;
  height: 122px;
  border-radius: 61px;
  background-image: url('./image/logo.jpeg');
  background-position: top;
  background-size: cover;
}

.tgme_page_title{
  font-size: 26px;
    line-height: 32px;
    font-weight: bold;
    text-align: center;
    max-width: 340px;
    padding: 0 10px;
    margin: 0 auto;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tgme_page_desc_header, .tgme_page_description{
   display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 125px;
    word-break: break-word;
    color: #fff;
    font-size: 16px;
}

.tgme_page_desc_header, .tgme_page_description, .tgme_page_additional{
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  margin: 10px 16px 0;
  padding: 0;
}

.tgme_page_action {
  text-align: center;
  margin: 54px 0 20px 0;
  line-height: 0;
}

a.tgme_action_button_new.shine {
  color: #fff;
  text-decoration: none;
  background-color: #1c93e3;
  padding: 10px 24px;
  border-radius: 22px;
  font-weight: bold;
}

a.tgme_action_button_new {
  background-color: #1c93e3;
}


@media screen and (max-width: 432px) {
  .tgme_page{
    margin: 16px 16px;
  }
}